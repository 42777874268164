import React, { SVGProps } from 'react';

export const Speaker = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <path
        d="M8.88975 5.22006C7.58273 5.84148 6.74995 7.15953 6.74995 8.60676V11.3722C6.74995 12.8173 7.58031 14.1338 8.88442 14.7564L16.7807 18.5259C18.2739 19.2387 20 18.1501 20 16.4954V3.49884C20 1.84567 18.2769 0.756969 16.7838 1.46682L8.88975 5.22006Z"
        fill="currentColor"
      />
      <path
        d="M2.25 6.2502C1.00736 6.2502 0 7.25756 0 8.5002V11.5002C0 12.7428 1.00736 13.7502 2.25 13.7502C3.49264 13.7502 4.5 12.7428 4.5 11.5002V8.5002C4.5 7.25756 3.49264 6.2502 2.25 6.2502Z"
        fill="currentColor"
      />
    </svg>
  );
};
