import React, { SVGProps } from 'react';

export const Ticket = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.59985 3.49999V1.99997H1.8C0.805887 1.99997 0 2.80586 0 3.79997V6C1.10457 6 2 6.89543 2 8C2 9.10457 1.10457 10 0 10V12.2C0 13.1941 0.805888 14 1.8 14H8.59985V12.5C8.59985 12.0029 9.0028 11.6 9.49985 11.6C9.99691 11.6 10.3999 12.0029 10.3999 12.5V14H14.2C15.1941 14 16 13.1941 16 12.2V9.99994C14.8954 9.99994 14 9.10451 14 7.99994C14 6.89537 14.8954 5.99994 16 5.99994V3.79997C16 2.80586 15.1941 1.99997 14.2 1.99997H10.3999V3.49999C10.3999 3.99704 9.99691 4.39999 9.49985 4.39999C9.0028 4.39999 8.59985 3.99704 8.59985 3.49999ZM10.3999 7.10001C10.3999 6.60296 9.99691 6.20001 9.49985 6.20001C9.0028 6.20001 8.59985 6.60296 8.59985 7.10001V8.90001C8.59985 9.39707 9.0028 9.80001 9.49985 9.80001C9.99691 9.80001 10.3999 9.39707 10.3999 8.90001V7.10001Z"
        fill="currentColor"
      />
    </svg>
  );
};
