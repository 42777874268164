import { SVGProps, useId } from 'react';

export const Wallet = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg {...props} viewBox="0 0 15 14">
      <g clip-path={`url(#${gradientId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666992 3.5C0.666992 2.05025 1.84224 0.875 3.29199 0.875H12.042C13.4917 0.875 14.667 2.05025 14.667 3.5V10.5C14.667 11.9497 13.4917 13.125 12.042 13.125H3.29199C1.84224 13.125 0.666992 11.9497 0.666992 10.5V3.5ZM12.3919 8.575C12.3919 7.92261 11.8631 7.39375 11.2107 7.39375C10.5583 7.39375 10.0294 7.92261 10.0294 8.575C10.0294 9.22739 10.5583 9.75625 11.2107 9.75625C11.8631 9.75625 12.3919 9.22739 12.3919 8.575ZM3.80239 2.45C3.37552 2.45 3.02947 2.80258 3.02947 3.2375C3.02947 3.67242 3.37552 4.025 3.80239 4.025H11.5316C11.9584 4.025 12.3045 3.67242 12.3045 3.2375C12.3045 2.80258 11.9584 2.45 11.5316 2.45H3.80239Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={gradientId}>
          <rect width="14" height="14" fill="white" transform="translate(0.666992)" />
        </clipPath>
      </defs>
    </svg>
  );
};
