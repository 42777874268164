import { SVGProps } from 'react';

export const SocialDebank = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.125 14.8C18.125 17.1196 16.2265 19 13.8846 19H5.875V16.2H13.8846C14.6652 16.2 15.2981 15.5732 15.2981 14.8C15.2981 14.0268 14.6652 13.4 13.8846 13.4H11.0577V10.6H13.8846C14.6652 10.6 15.2981 9.9732 15.2981 9.2C15.2981 8.4268 14.6652 7.8 13.8846 7.8H5.875V5H13.8846C16.2265 5 18.125 6.8804 18.125 9.2C18.125 10.2757 17.7167 11.2569 17.0453 12C17.7167 12.7431 18.125 13.7243 18.125 14.8Z"
        fill="currentColor"
      />
      <path
        d="M5.875 5C9.74099 5 12.875 8.13401 12.875 12C12.875 15.866 9.74099 19 5.875 19V16.2C8.1946 16.2 10.075 14.3196 10.075 12C10.075 9.6804 8.1946 7.8 5.875 7.8V5Z"
        fill="currentColor"
      />
    </svg>
  );
};
