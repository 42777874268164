import React, { SVGProps } from 'react';

export const ThumbsUpOutlined = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62402 1.83521L5.67725 6.8301C5.41098 7.51327 4.75285 7.96312 4.01962 7.96312H3.65109C3.17714 7.96312 2.79292 8.34733 2.79292 8.82129V12.3419C2.79292 12.8158 3.17714 13.2 3.65109 13.2H9.98818C10.8569 13.2 11.5922 12.5583 11.7095 11.6975L12.1895 8.17682C12.3317 7.13374 11.5209 6.20489 10.4682 6.20489H9.6964C8.71384 6.20489 7.91732 5.40836 7.91732 4.4258V2.25052C7.91732 2.05886 7.79491 1.89579 7.62402 1.83521ZM6.01375 1.01003C6.24876 0.40705 6.82964 0.0100098 7.47681 0.0100098C8.7142 0.0100098 9.71731 1.01312 9.71731 2.25052V4.40489H10.4682C12.6117 4.40489 14.2626 6.29613 13.973 8.41997L13.493 11.9406C13.2541 13.6933 11.7571 15 9.98818 15H3.65109C2.18302 15 0.99292 13.8099 0.99292 12.3419V8.82129C0.99292 7.35322 2.18302 6.16312 3.65109 6.16312H4.00532L6.01375 1.01003L6.84883 1.33551L6.85231 1.33686M6.01375 1.01003L6.84883 1.33551L6.01375 1.01003Z"
        fill="currentcolor"
      />
    </svg>
  );
};
