import { SVGProps } from 'react';

export const TrendUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1 3.89982C5.1 3.40276 5.50294 2.99982 6 2.99982H12.0017C12.554 2.99982 13.0017 3.44753 13.0017 3.99982V9.99981C13.0017 10.4969 12.5987 10.8998 12.1017 10.8998C11.6046 10.8998 11.2017 10.4969 11.2017 9.99981V6.07164L4.5362 12.7329C4.18461 13.0843 3.61477 13.0841 3.2634 12.7325C2.91204 12.381 2.91222 11.8111 3.2638 11.4598L9.92793 4.79982H6C5.50294 4.79982 5.1 4.39687 5.1 3.89982Z"
        fill="currentColor"
      />
    </svg>
  );
};
