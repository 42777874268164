import React, { SVGProps } from 'react';

export const WalletOutlined = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10.4469 6.64999C11.1925 6.64999 11.7969 7.25442 11.7969 8C11.7969 8.74559 11.1925 9.34999 10.4469 9.34999C9.70135 9.34999 9.09692 8.74559 9.09692 8C9.09692 7.25442 9.70135 6.64999 10.4469 6.64999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.49995C1.34315 1.49995 0 2.8431 0 4.49995V11.5C0 13.1568 1.34315 14.5 3 14.5H13C14.6569 14.5 16 13.1568 16 11.5V4.49995C16 2.8431 14.6569 1.49995 13 1.49995H3ZM13 3.29996H3C2.33726 3.29996 1.8 3.83721 1.8 4.49995V11.5C1.8 12.1627 2.33726 12.7 3 12.7H13C13.6627 12.7 14.2 12.1627 14.2 11.5V4.49995C14.2 3.83721 13.6627 3.29996 13 3.29996Z"
        fill="currentColor"
      />
    </svg>
  );
};
